.Footer-container{
    position: relative;
}

.Footer-container>hr{
    border: 1px solid var(--lightgray);
}

.footer{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4rem;
    height: 20rem;
}

.social-links{
    display: flex;
    gap: 4rem;
}

.social-links>img{
    width: 2rem;
    height: 2rem;
    margin-top: 8rem;
    cursor: pointer;
}

.logo-f>img{
    height: 3rem;
    width: 12rem;
}

.f-blur-1{
    height: 12rem;
    width: 26rem;
    bottom: 0;
    right: 25%;
    filter: blur(200px);
    background: red;
}

.f-blur-2{
    height: 12rem;
    width: 26rem;
    bottom: 0;
    left: 25%;
    filter: blur(200px);
    background: rgb(255, 115, 0);
}
